import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let formData = new FormData()
    let el = $(this.data.element)
    let url = el.data('url').replace(":id", id)
    let model = el.data('model')

    formData.append(`${model}[position]`, event.newIndex + 1);

    Rails.ajax({
      url: url,
      type: 'PATCH',
      data: formData
    })
  }
}
