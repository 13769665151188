import { Controller } from "stimulus"
import { get } from "@rails/ujs"

export default class extends Controller {
  connect() {
  }

  go(event) {
    let url = event.target.value;

    fetch(
      url,
      {
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      }
    )
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(_ => history.replaceState(history.state, "", url))
  }
}
