$(document).on('turbo:load', function() {
  initNotApplicable();
});

function initNotApplicable(){
  $('.js-notApplicable').each(function() {
    $(this).on('click', function() {
      var notApplicableFieldId = this.id;
      var valueFieldId = notApplicableFieldId.replace('_not_applicable', '_value');
      var valueField = $('#' + valueFieldId);
      valueField.prop('disabled', function(i, v) { return !v; });
    });
  });
}
