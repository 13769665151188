import { Controller } from 'stimulus'
import { get } from '@rails/request.js'
import TomSelect from 'tom-select';

export default class extends Controller {
  static targets = [
    'employeeLookup',
  ]
  static values = {
    url: String
  }

  connect() {
    var config = {
      openOnFocus: false,
      valueField: 'value',
      preload: 'focus',
      hideSelected: false,
      load: (q, callback) => this.search(q, callback),
      onItemAdd: (value, item) => this.hide_select(this)
    }
    new TomSelect(this.element, config)
  }

  hide_select(el) {
    $(el).hide();
  }

  async search(q, callback) {
    const response = await get(this.urlValue, {
      query: { query: q },
      responseKind: 'json'
    })

    if (response.ok) {
      const list = await response.json
      callback(list)
    } else {
      callback()
    }
  }
}
