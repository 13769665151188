window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Oops. We only support jpeg, png and pdf files.")
  }

  const maxFileSize = 1024 * 1024 // 1MB
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("Oops. We only support attachment files upto size 1MB!")
  }
})
