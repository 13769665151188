import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    var $flashAlert = $('.js-flash-alert'),
      $flashAlertClose = $('.js-flash-alert-close');

    if ($flashAlert.length > 0) {
      $flashAlertClose.on('click', function() {
        $(this).parents('div.js-flash-alert')
          .addClass('is-transparent');
      });

      window.setTimeout(function() {
        $flashAlert.addClass('is-transparent');
      }, 5000);
    }
  }
}
