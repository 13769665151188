import { Controller } from 'stimulus'
import { get } from '@rails/request.js'
import TomSelect from 'tom-select';

export default class extends Controller {
  static values = {
    employeeUrl: String,
    url: String
  }

  connect() {
    var config = {
      openOnFocus: false,
      valueField: 'value',
      preload: 'focus',
      load: (q, callback) => this.search(q, callback),
      onItemAdd: (value, item) => this.navigate(value)
    }
    new TomSelect(this.element, config)
  }

  navigate(value) {
    var employeeUrl = this.employeeUrlValue.replace(/EMPLOYEE_ID/g, value)

    fetch(employeeUrl)
      .then(_ => window.location.href = employeeUrl)
  }

  async search(q, callback) {
    const response = await get(this.urlValue, {
      query: { query: q },
      responseKind: 'json'
    })

    if (response.ok) {
      const list = await response.json
      callback(list)
    } else {
      callback()
    }
  }
}
