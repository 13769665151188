require('packs/public/review_response_fields')
require('packs/public/trix-editor-overrides')

import Rails from '@rails/ujs'
import { Turbo } from "@hotwired/turbo-rails"
import * as ActiveStorage from '@rails/activestorage'
import "trix"
import "@rails/actiontext"
import 'tom-select'
import 'channels'
import { Foundation } from 'foundation-sites'
import 'controllers'

window.Rails = Rails

Rails.start()
ActiveStorage.start()

document.addEventListener("turbo:load", function() {
  jQuery(function(){
    $(document).foundation();
  });
});

