import { Controller } from 'stimulus'
import debounce from 'lodash.debounce'

export default class extends Controller {
  static values = {
    delay: Number
  }

  initialize() {
    this.debouncedSave = debounce(this.save, this.delayValue);
  }

  connect() {
    const formElement = this.element;
    const self = this;

    const fields = formElement.querySelectorAll('select, input, textarea');
    fields.forEach(function(field) {
      field.addEventListener('input', self.debouncedSave);
    });

    const trixElement = formElement.querySelector("trix-editor")
    if (trixElement) {
      trixElement.addEventListener('trix-change', this.debouncedSave);
    }
  }

  save = () => {
    const formElement = this.element;

    if (window._rails_loaded) {
      // @ts-ignore
      Rails.fire(formElement, 'submit')
    } else {
      formElement.requestSubmit()
    }

    this.enableCommitButton(formElement)
  }

  enableCommitButton(element) {
    const submitButtons = element.querySelectorAll('input[type="submit"]');

    submitButtons.forEach(function(button) {
      $(button).removeAttr('data-disable-with');
    });
  }

  disconnect() {
    this.debouncedSave.cancel();
  }
}
