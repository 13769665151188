import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'childCommentTemplate',
    'groupLink',
    'groupTemplate',
    'reviewTemplate',
    'selectOptionTemplate'
  ]

  connect() {
    this.childCommentTargetClass = 'js-child-comment-target'
    this.childCommentWrapperClass = 'js-child-comment-wrapper'
    this.groupWrapperClass = 'js-group-fields-wrapper'
    this.nestedFieldsTargetClass = 'js-nested-fields-target'
    this.templateFieldsWrapperClass = 'js-template-fields-wrapper'
    this.selectOptionsWrapperClass = 'js-select-options-wrapper'
    this.selectOptionsTargetClass = 'js-select-options-target'
    this.selectOptionTemplateClass = 'js-select-option-template'
  }

  add_group (event) {
    event.preventDefault()

    // Set the group ID
    var groupCount = $('.' + this.groupWrapperClass).length;
    var groupId = (groupCount || 0) + 1;

    // Get the template group content and stick it on the page
    var groupContent = this.groupTemplateTarget.innerHTML
      .replace(/GROUP_ID/g, groupId)
    this.groupLinkTarget.insertAdjacentHTML('beforebegin', groupContent)
  }

  remove_group(event) {
    event.preventDefault()

    // Get the closest group wrapper
    let groupId = event.target.dataset.groupId;
    let wrapper = this.fetch_element(
      event, groupId, 'group', this.groupWrapperClass
    );

    // Remove it from the page
    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove()
    } else {
    // Set existing fields to destroy
      this.mark_fields_for_destroy(wrapper);
    }
  }

  update_group(event) {
    let groupId = event.target.dataset.groupId;
    let wrapper = this.fetch_element(
      event, groupId, 'group', this.groupWrapperClass
    );

    // Set the values for group_name field
    let newValue = event.target.value;
    this.set_group_name(wrapper, newValue);
  }

  add_field(event) {
    event.preventDefault()

    // Fetch the template and update the attribute id
    var content = this.reviewTemplateTarget.innerHTML
      .replace(/NEW_RECORD/g, new Date().getTime())

    // Get the closest group
    let groupId = event.target.dataset.groupId;
    let groupWrapper = this.fetch_element(
      event, groupId, 'group', this.groupWrapperClass
    );

    // Stick the field content in the correct group element
    var fieldsWrapper = $(groupWrapper).find('.' + this.nestedFieldsTargetClass);
    fieldsWrapper.append(content);

    // Update group name
    let groupName = groupWrapper
      .querySelector("input[name*='group_template_name']").value;

    this.set_group_name(groupWrapper, groupName)
  }

  remove_field(event) {
    event.preventDefault()

    // Get target field
    let fieldId = event.target.dataset.fieldId;
    let wrapper = this.fetch_element(
      event, fieldId, 'field', this.templateFieldsWrapperClass
    );

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove()
    } else {
      // Existing records are hidden and flagged for deletion
      this.mark_fields_for_destroy(wrapper);
    }
  }

  display_select_options(event) {
    // Get the select option element
    let fieldId = event.target.dataset.fieldId;
    let wrapper = this.fetch_element(
      event, fieldId, 'field', this.templateFieldsWrapperClass
    );
    let selectOptionsWrapper = $(wrapper)
      .find('.' + this.selectOptionsWrapperClass)

    // Check if we need to add select items
    var value = event.target.value;
    if (value == 'select' || value == 'radio') {
      selectOptionsWrapper.removeClass('hide');
    } else {
      // If it's in a new record we can remove them
      if (wrapper.dataset.newRecord == 'true') {
        let templateFields = $(wrapper).find('.' + this.selectOptionTemplateClass);
        templateFields.remove();
      } else {
        // For existing records we need to set the values to nil
        $(wrapper).find("input[name*='select_options']").each(function(idx, input) {
          input.value = ''
        });
      }

      // Hide the wrapper element
      selectOptionsWrapper.addClass('hide');
    }
  }

  add_option_item(event) {
    event.preventDefault()

    // Get the field attribute name from the clicked button
    let objectName = event.target.getAttribute('data-object-name')

    // Update the attribute names on the fields
    var timeNow = new Date().getTime();
    var selectOptionName = objectName + '[select_options]' + `[${timeNow}]`
    let templateSelectOption = this.selectOptionTemplateTarget.innerHTML
      .replace(/SELECT_OPTION/g, selectOptionName);

    // Stick it on the page after the other options
    let wrapper = event.target.closest('.' + this.templateFieldsWrapperClass);
    let optionsTarget = $(wrapper).find('.' + this.selectOptionsTargetClass);
    optionsTarget.append(templateSelectOption);
  }

  remove_option_item(event) {
    event.preventDefault()

    let wrapper = event.target.closest('.' + this.selectOptionTemplateClass);
    wrapper.remove();
  }

  handle_comment_checkbox(event) {
    event.preventDefault()

    var checked = Boolean(event.target.checked);
    var wrapper = event.target.closest('.' + this.templateFieldsWrapperClass);
    var newRecord = (wrapper.dataset.newRecord == 'true')
    var childTarget = $(wrapper).find('.' + this.childCommentTargetClass);
    var childWrapper = $(wrapper).find('.' + this.childCommentWrapperClass);

    if (checked) {
      this.add_comment_field(wrapper, newRecord, childTarget, childWrapper)
    } else {
      this.remove_comment_field(wrapper, newRecord, childTarget, childWrapper)
    }

    return checked;
  }

  add_comment_field(wrapper, newRecord, childTarget, childWrapper) {
    var fieldsExist = Boolean(childWrapper.length);
    if (fieldsExist) {
      // Set _destroy to false
      $(childWrapper).find("input[name*='_destroy']").each(function(idx, input) {
        input.value = false
      });
    } else {
      // Add template
      this.insert_comment_template(wrapper, childTarget);
    }
  }

  insert_comment_template(wrapper, childTarget) {
    var timeNow = new Date().getTime();
    var parentIndex = wrapper.getAttribute('data-field-id')
    var template = this.childCommentTemplateTarget.innerHTML
      .replace(/NEW_CHILD_RECORD/g, timeNow)
      .replace(/PARENT_INDEX/g, parentIndex);

    childTarget.append(template);
  }

  remove_comment_field(wrapper, newRecord, childTarget, childWrapper) {
    var newCommentRecord = (childWrapper[0].dataset.newRecord == 'true');

    if (newCommentRecord) {
      // Remove field
      childWrapper.remove();
    } else {
      // Set _destroy to true
      var el = childWrapper[0];
      this.mark_fields_for_destroy(el);
    }
  }

  fetch_element(event, id, elementType, className) {
    var elementId = 'data-' + elementType + '-id=' + `"${id}"`

    return event.target.closest("." + className  + `[${elementId}]`)
  }

  set_group_name(wrapper, groupName) {
    var groupNameInputs = $(wrapper).find("input[name*='group_name']");

    groupNameInputs.each(function(idx, input) {
      input.value = groupName;
    });
  }

  mark_fields_for_destroy(wrapper) {
    $(wrapper).find("input[name*='_destroy']").each(function(idx, input) {
      input.value = 1
    });
    wrapper.style.display = 'none'
  }
}
