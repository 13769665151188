import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'employeeHiddenField',
    'employeeSelectField',
    'employeeWrap',
    'option'
  ]

  connect() {
  }

  set_direction(event) {
    this.optionTargets.forEach((el, i) => {
      el.classList.toggle('active', event.target == el)
    })
    $(this.employeeWrapTarget).empty()

    let value = $(event.target).data('value');
    if (value == 'employee') {
      var fieldContent = this.employeeSelectFieldTarget.innerHTML
      this.employeeWrapTarget.classList.toggle('hide', false)
    } else {
      var fieldContent = this.employeeHiddenFieldTarget.innerHTML
      this.employeeWrapTarget.classList.toggle('hide', true)
    }

    this.employeeWrapTarget.insertAdjacentHTML('beforeend', fieldContent)
  }
}
