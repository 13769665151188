import { Controller } from 'stimulus'
import { get } from '@rails/request.js'
import TomSelect from 'tom-select';

export default class extends Controller {
  static values = {
    performSearch: String
  }

  connect() {
    var config = {
      create: true,
      valueField: 'value',
      plugins: {
        remove_button: {
          title:'Remove this item',
        }
      },
      openOnFocus: false,
      onChange: function(value, item) {
        this.setTextboxValue('');
        this.refreshOptions();

        var filterForm = document.getElementById('filter_form');
        if (filterForm) {
          filterForm.submit();
        }
      },
      persist: false,
    }
    new TomSelect(this.element, config)
  }
}
