import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'learningPathSteps',
    'learningPathStepTemplate',
    'objectiveTemplate'
  ]

  connect() {
    this.learningPathStepsWrapperClass = 'js-learning-path-steps-wrapper'
    this.learningPathStepWrapperClass = 'js-learning-path-step-wrapper'
    this.learningPathStepObjectivesWrapperClass = 'js-learning-path-step-objectives-wrapper'
    this.learningPathStepObjectiveWrapperClass = 'js-learning-path-step-objective-wrapper'
  }

  add_step(event) {
    event.preventDefault()

    var target = this.learningPathStepsTarget
    var content = this.learningPathStepTemplateTarget.innerHTML
      .replace(/NEW_LEARNING_PATH_STEP_RECORD/g, new Date().getTime())
      .replace(/NEW_LEARNING_PATH_STEP_OBJECTIVE_RECORD/g, new Date().getTime())
    var template = $(content)[0]
    var wrapper = this.learningPathStepsWrapperClass

    this.add_element(wrapper, target, template)
  }

  add_objective(event) {
    event.preventDefault()

    // Get the closest step
    let learningPathStepId = $(event.target).data('learningPathStepId');
    let learningPathStepWrapper = this.fetch_element(
      event,
      learningPathStepId,
      'learning-path-step',
      this.learningPathStepWrapperClass
    );

    // Stick the objective field content in the step element
    var content = this.objectiveTemplateTarget.innerHTML
      .replace(/NEW_LEARNING_PATH_STEP_RECORD/g, learningPathStepId)
      .replace(/NEW_LEARNING_PATH_STEP_OBJECTIVE_RECORD/g, new Date().getTime())
    var template = $(content)[0]
    var target = $(learningPathStepWrapper)
      .find('.' + this.learningPathStepObjectivesWrapperClass)

    this.add_element(learningPathStepWrapper, target, template)
  }

  remove_step(event) {
    event.preventDefault()

    // Get target field
    let learningPathStepId = $(event.target).data('learningPathStepId');
    let wrapper = this.fetch_element(
      event,
      learningPathStepId,
      'learning-path-step',
      this.learningPathStepWrapperClass
    );

    this.remove_element(wrapper);
  }

  remove_objective() {
    event.preventDefault()

    let learningPathStepObjectiveId = $(event.target)
      .data('learningPathStepObjectiveId');
    let wrapper = this.fetch_element(
      event,
      learningPathStepObjectiveId,
      'learning-path-step-objective',
      this.learningPathStepObjectiveWrapperClass
    );

    this.remove_element(wrapper);
  }

  add_element(wrapper, target, template) {
    target.append(template);
  }

  remove_element(wrapper) {
    var newRecord = wrapper.data('newRecord');

    if (newRecord  == 'true') {
      wrapper.remove()
    } else {
    // Set existing fields to destroy
      this.mark_fields_for_destroy(wrapper);
    }
  }

  fetch_element(event, id, elementType, className) {
    var elementDataId = 'data-' + elementType + '-id=' + `"${id}"`
    var elementId = ("." + className  + `[${elementDataId}]`)

    return $(event.target).closest(elementId)
  }

  mark_fields_for_destroy(wrapper) {
    $(wrapper).find("input[name*='_destroy']").each(function(idx, input) {
      input.value = 1
    });
    $(wrapper).css('display', 'none')
  }
}
